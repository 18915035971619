import jQuery from 'jquery';

jQuery(document).ready(function () {
    var updateTabCounters = function() {
        jQuery('.tab-pane').each(function() {
            var count = jQuery(this).find('input[type="checkbox"]').filter(':checked').length;
            var counterBadge = jQuery('.tab-counter-' + jQuery(this).attr('id'));

            if (count > 0) {
                counterBadge.text(count);
            } else {
                counterBadge.text('');
            }
        });
    };

    jQuery('input[type="checkbox"]').on('click, change', function() {
        updateTabCounters();
    });

    updateTabCounters();
});
