import CollectionRow from './CollectionRow';
import FormUtil from './FormUtil';

export default class FormRow extends CollectionRow {

    constructor(props) {
        super(props);

        FormUtil.init(this.$container);
    }

}
