import jQuery from 'jquery';
import FilterBuilder from './FilterBuilder';

jQuery(document).ready(function () {
    jQuery('#eventAddEndDate').on('click', function(event) {
        event.preventDefault();
        jQuery(this).addClass('d-none');
        jQuery('#eventEndDateContainer').removeClass('d-none');
    });

    jQuery('#eventAddEndTime').on('click', function(event) {
        event.preventDefault();
        jQuery(this).addClass('d-none');
        jQuery('#eventEndTimeContainer').removeClass('d-none');
    });

    // integrate filter builder for activities and events
    new FilterBuilder({
        toggleButton: document.getElementById('filterToggle'),
        container: document.getElementById('filterBuilder'),
        filterAddEndDateButton: document.getElementById('filterAddEndDate'),
        filterAddEndDateContainer: document.getElementById('filterAddEndDateContainer'),
    });
});
