import jQuery from 'jquery';

export default class BackButtonDetection {

    static init() {
        let $input = jQuery(document.getElementById('backButtonDetection'));

        BackButtonDetection.backButtonClicked = 'loaded' === $input.val();

        $input.val('loaded');
    }

    static isBackButtonClicked() {
        return BackButtonDetection.backButtonClicked;
    }

}
