import jQuery from 'jquery';
import _ from 'underscore';

export default class CollectionRow {

    constructor(props) {
        props = _.extend({ allowDelete: true }, props);

        this.$container = jQuery(props.container);

        if (props.allowDelete) {
            this.$container.children('.col-sm-3')
                .removeClass('col-sm-3')
                .addClass('col-sm-2');

            this.$container.children('.col-sm-8')
                .removeClass(/* remove all md, sm and lg classes */)
                .addClass('col-sm-10')
                .addClass('order-1');

            // Swap column positions
            // Fetch children anew in case the previous match did not match anything
            // This happens for example in the merge view, where the classes are
            // correct already
            this.$container.children('.col-sm-2').addClass('order-2');
            this.$container.children('.col-sm-10').addClass('order-1');

            this.initDeleteButton();
        } else {
            this.$container.children('.col-sm-3, .col-sm-2').remove();

            this.$container.children('.col-sm-8, .col-sm-10')
                .removeClass(/* remove all md, sm and lg classes */)
                .addClass('col-sm-12');
        }
    }

    initDeleteButton() {
        let that = this;

        // The btn-delete class is used during merging
        let $button = jQuery('<a class="btn btn-danger btn-delete pull-right" href="#"><i class="far fa-trash"></i></a>');
        let $buttonRow = this.$container.children('.col-sm-2');

        $buttonRow.append($button);

        // Adjust vertical position of the button to match fields of any
        // embedded forms
        let $embeddedForm = this.$container.find('.col-sm-10 > .form-vertical-embedded');

        if ($embeddedForm.length > 0) {
            let $firstInput = $embeddedForm.find('input, select, textarea').first();

            if ($firstInput.length > 0) {
                $buttonRow.css('margin-top', $firstInput.offset().top - $buttonRow.offset().top);
            }
        }

        $button.on('click', function (e) {
            e.preventDefault();
            that.$container.remove();
        });
    }

}
