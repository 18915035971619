import jQuery from 'jquery';

jQuery(document).ready(function () {
    var clearTargetValuesForPair = function(container) {
        container.children('.contact-merge-target').find('a.btn-delete').each(function() {
            jQuery(this).click();
        });
    };

    var replaceValues = function(source, target) {
        if (source.attr('type') == 'checkbox' || source.attr('type') == 'radio') {
            target.prop('checked', source.is(':checked'));
            jQuery.uniform.update();
        } else if (target.hasClass('select2-hidden-accessible')) {
            var data = source.select2('data');
            for (let i = 0; i < data.length; i++) {
                // ajax-powered select2 lists don't have any options set, we have to manually provide them
                var option = target.children('option[value="' + data[i].id + '"]');
                if (!option.length) {
                    target.append(jQuery('<option value="' + data[i].id + '">' + data[i].text + '</option>'));
                }
            }
            target.val(source.val()).trigger('change');
        } else {
            target.val(source.val());
        }
    };

    var replaceValuesForPair = function(container) {
        container.children('.contact-merge-source').find('input, select, textarea').not('.tt-hint').each(function() {
            var source = jQuery(this);
            var sourceId = source.attr('id');

            // Some elements without ID may be matched as well
            if (sourceId) {
                var targetId = sourceId.replace(/_source/, '_target');
                var target = jQuery('#' + targetId);

                replaceValues(source, target);
            }
        });
    };

    var addValues = function(container, targetCollection, replaceFrom, replaceTo) {
        if (typeof targetCollection !== 'undefined') {
            var sourceDiv = container.children('.contact-merge-source');
            var targetContainer = jQuery('.contact-merge-target .' + targetCollection).parents('.contact-merge-pair').first();
        } else {
            var sourceDiv = container.children('.contact-merge-source').children('div').children('.form-group');
            var targetContainer = container;
        }

        if (sourceDiv.length == 0) {
            // there is no collection involved, must be a multi-select or checkboxes (expanded == true and multiple == true)
            var sourceSelect = container.children('.contact-merge-source').children('select.select2').first();
            var targetSelect = container.children('.contact-merge-target').children('select.select2').first();

            // no multi-select => checkboxes
            if (sourceSelect.length == 0 || targetSelect.length == 0) {
                container.children('.contact-merge-source').find('input[type="checkbox"]:checked').each(function() {
                    var source = jQuery(this);
                    var sourceId = source.attr('id');
                    var targetId = sourceId.replace(/_source/, '_target');
                    var target = jQuery('#' + targetId);

                    replaceValues(source, target);
                });

                return;
            }

            var targetVal = targetSelect.val();

            // remove duplicate values. @see http://stackoverflow.com/a/23080662/22592
            var sourceVal = sourceSelect.val().filter(function (item) {
                return targetVal.indexOf(item) < 0;
            });
            targetVal = targetVal.concat(sourceVal);

            // copy option nodes that are not yet present in the target select
            var sourceOptions = sourceSelect.find('option').clone().filter(function (index, option) {
                return targetSelect.find('option[value="' + jQuery(option).attr('value') + '"]').length == 0;
            });

            targetSelect.append(sourceOptions);
            targetSelect.val(targetVal).trigger('change');

            return;
        }

        var offset = targetContainer.children('.contact-merge-target').children('.collection-holder').first().data('index');
        var addButton = targetContainer.children('.contact-merge-target').children('div').children('.form-group.addLink').find('a.btn');

        sourceDiv.each(function() {
            var sourceDiv = jQuery(this);
            addButton.click();

            sourceDiv.find('input, select, textarea').not('.tt-hint').each(function() {
                var source = jQuery(this);
                var sourceId = source.attr('id');
                if (typeof replaceFrom !== 'undefined' && typeof replaceTo !== 'undefined') {
                    var targetId = sourceId.replace(/_source/, '_target_' + offset);
                    targetId = targetId.replace(new RegExp('_' + replaceFrom + '_'), '_' + replaceTo + '_');
                } else {
                    var targetId = sourceId.replace(/_source_\d+/, '_target_' + offset);
                }
                var target = jQuery('#' + targetId);

                replaceValues(source, target);
            });

            offset++;
        });
    };

    jQuery('.replace-action').on('click', function() {
        var container = jQuery(this).parents('.contact-merge-pair').first();
        if (container.find('.collection-holder').length && container.find('.form-group.addLink').length) {
            clearTargetValuesForPair(container);
            addValues(container);
        } else {
            replaceValuesForPair(container);
        }
    });

    jQuery('.add-action').on('click', function() {
        addValues(jQuery(this).parents('.contact-merge-pair').first(), jQuery(this).data('targetCollection'), jQuery(this).data('replaceFrom'), jQuery(this).data('replaceTo'));
    });
});
