import jQuery from 'jquery';
import 'select2';

export default class Dropdown {

    constructor(props) {
        this.$container = jQuery(props.container);

        this.$container.select2(this.getSelect2Params());
    }

    getSelect2Params() {
        let params = {
            allowClear: !this.$container.hasClass('select2-no-clear'),
            placeholder: 'Bitte wählen…',
            theme: 'bootstrap'
        };

        if (this.$container.data('allow-add')) {
            params.tags = true;

            params.createTag = function (params) {
                return {
                    id: params.term,
                    text: params.term,
                    newOption: true
                };
            };

            params.templateResult = function (data) {
                var $result = jQuery('<span></span>');

                $result.text(data.text);

                if (data.newOption) {
                    $result.append(' ' + translations.dropdown.newSuffix);
                }

                return $result;
            };
        }

        return params;
    }

}
