import jQuery from 'jquery';

const regionIdSelectHtmlId = '#send_or_schedule_notification_regionId';
const targetAudienceSelectHtmlId = '#send_or_schedule_notification_targetAudience';
const deliveryTypeSelectHtmlId = '#send_or_schedule_notification_deliveryType';
const scheduledAtDateHtmlId = '#notification_field_scheduledAtDate_id';
const scheduledAtTimeHtmlId = '#send_or_schedule_notification_scheduledAtTime';
const clientTimeZoneHtmlId = '#send_or_schedule_notification_clientTimeZone';

jQuery(document).ready(function () {
    const $regionSelectRow = jQuery(regionIdSelectHtmlId).closest('.row');
    const $targetAudienceSelect = jQuery(targetAudienceSelectHtmlId);
    const $deliveryTypeSelect = jQuery(deliveryTypeSelectHtmlId);
    const $scheduledAtDateRow = jQuery(scheduledAtDateHtmlId).closest('.row');
    const $scheduledAtTimeRow = jQuery(scheduledAtTimeHtmlId).closest('.row');

    const configureClientTimeZone = () => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const $clientTimeZone = jQuery(clientTimeZoneHtmlId);
        const $clientTimeZoneRow = $clientTimeZone.closest('.row');

        $clientTimeZone.val(timezone);
        $clientTimeZoneRow.hide();
    };

    const showOrHideRegionRow = () => {
        const targetAudience = $targetAudienceSelect.val();

        if ('province' === targetAudience) {
            $regionSelectRow.show();
        } else {
            $regionSelectRow.hide();
        }
    };

    const showOrHideScheduledAtRow = () => {
        const deliveryType = $deliveryTypeSelect.val();

        if ('scheduledForLater' === deliveryType) {
            $scheduledAtDateRow.show();
            $scheduledAtTimeRow.show();
        } else {
            $scheduledAtDateRow.hide();
            $scheduledAtTimeRow.hide();
        }
    };

    // Initially hide some values
    showOrHideRegionRow();
    showOrHideScheduledAtRow();
    configureClientTimeZone();

    // On change hide or show the region input
    $targetAudienceSelect.on('change', function (event) {
        event.preventDefault();

        showOrHideRegionRow();
    });

    // On change hide/show the scheduleAt inputs
    $deliveryTypeSelect.on('change', function (event) {
        event.preventDefault();

        showOrHideScheduledAtRow();
    });
});
