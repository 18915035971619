import jQuery from 'jquery';
import FormUtil from './FormUtil';
import { navigateTo } from './navigation-utils';
import 'bootstrap';

function fillModal(modal, html) {
    modal.find('.modal-content').html(html);

    // Ensure the submit triggers our AJAX call instead of submitting the form
    // against the URL we currently are on
    // Beware that changing the modal content is not enough as if it is changed
    // then  the submit event will be back to the default one
    modal
        .find('form[name="report_duplicate"]')
        .on('submit', function (event) {
            event.preventDefault();
            submitReportDuplicateModal(modal);
        });

    FormUtil.init(modal[0]);
}

function submitReportDuplicateModal(modal) {
    const $buttons = modal.find('button');

    $buttons.prop('disabled', true);

    jQuery.post({
        url: '/contacts/contacts/' + CURRENT_CONTACT_VIEW_ID + '/report-duplicate',
        data: jQuery('form[name="report_duplicate"]').serialize(),
        dataType: 'json',
        success: function (data) {
            var redirectUrl = data.redirectUrl;

            // If there is no redirect, close the modal and stay on the same page
            if (!redirectUrl) {
                modal.modal('hide');

                return;
            }

            // Follow the redirect: since this should redirect to a different
            // section it is ok to drop the current query parameters
            navigateTo(redirectUrl);
        },
        error: function (xhr) {
            // Update the modal content on error to show the validation errors
            fillModal(modal, xhr.responseJSON.content);
        },
    });
}

jQuery(document).ready(function () {
    jQuery('#openReportDuplicateModal').on('click', function (event) {
        event.preventDefault();

        const modal = jQuery('#helper-modal');

        modal.html('<div class="modal-dialog modal-medium" role="document"><div class="modal-content"><div class="modal-body"><div class="loading"></div></div></div></div>');

        modal.modal('show');

        modal.on('hidden.bs.modal', function () {
            // Clear modal
            modal.html('');
        });

        jQuery.ajax({
            url: '/contacts/contacts/' + CURRENT_CONTACT_VIEW_ID + '/report-duplicate?iframe=1',
            dataType: 'json',
            success: function (data) {
                fillModal(modal, data.content);
            }
        });
    });
});
