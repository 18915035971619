import jQuery from 'jquery';

const CHANNEL_EVENT = 'Veranstaltung';

const CHANNEL_EINSATZ = 'Einsatz';

const CHANNEL_PHONE = 'Telefon';

const CHANNEL_EMAIL = 'E-Mail';

export default class ContactHistoryEntryForm {

    constructor(props) {
        this.$container = jQuery(props.container);

        this.$channelField = this.$container.find('.select-contact-history-channel');
        this.$eventFields = this.$container.find('.contact-history-entry-field-event');
        this.$phoneFields = this.$container.find('.contact-history-entry-field-phone');

        this.$emailFields = this.$container.find('.contact-history-entry-field-email');

        this._onChannelSelected = this._onChannelSelected.bind(this);

        this.$channelField.on('change', this._onChannelSelected);

        this._onChannelSelected();
    }

    _onChannelSelected() {
        var channel = this.$channelField.val();

        switch (channel) {
            case CHANNEL_EVENT:
            case CHANNEL_EINSATZ:
                this.$phoneFields.hide();
                this.$emailFields.hide();
                this.$eventFields.show();
                break;

            case CHANNEL_PHONE:
                this.$emailFields.hide();
                this.$eventFields.hide();
                this.$phoneFields.show();
                break;

            case CHANNEL_EMAIL:
                this.$phoneFields.hide();
                this.$eventFields.hide();
                this.$emailFields.show();
                break;

            default:
                this.$phoneFields.hide();
                this.$eventFields.hide();
                this.$emailFields.hide();
                break;
        }
    }
}

jQuery(document).ready(function () {
    new ContactHistoryEntryForm({container: $('.form')});
});
