export function updateEndDateAutomaticallyOnStartDateChange(config) {
    const {
        startDateInput,
        startTimeInput,
        endDateInput,
        endTimeInput,
    } = config;
    const defaultDuration = config.defaultDuration ?? null; // in milliseconds
    const getEndDateInputValue = config.getEndDateInputValue
        ?? (() => endDateInput.val());
    const onEndDateWasUpdated = config.onEndDateWasUpdated
        ?? (() => {});

    // Create a snapshot of the initial start date
    storeAsPreviousValue.apply(startDateInput);
    storeAsPreviousValue.apply(startTimeInput);

    function updateEndDateOnStartDateTimeChange() {
        const startDate = getStartDate();

        if (null === startDate) {
            return;
        }

        const duration = calculateDurationUntilEndDateInMilliseconds(getPreviousStartDate());

        if (null === duration) {
            return;
        }

        const nextEndDate = new Date(startDate.getTime() + duration);

        setEndDate(nextEndDate);
    }

    function calculateDurationUntilEndDateInMilliseconds(startDate) {
        const endDate = getEndDate();

        return calculateDurationInMilliseconds(startDate, endDate, defaultDuration);
    }

    function getPreviousStartDate() {
        const prevDateValue = getPreviousValue(startDateInput);
        const prevTimeValue = getPreviousValue(startTimeInput);

        return mapDateTimeInputValuesToDate(prevDateValue, prevTimeValue);
    }

    function getStartDate() {
        return mapDateTimeInputsToDate(startDateInput, startTimeInput);
    }

    function getEndDate() {
        return mapDateTimeInputValuesToDate(
            getEndDateInputValue(),
            endTimeInput.val(),
        );
    }

    function setEndDate(endDate) {
        setDateTimeInput(endDate, endDateInput, endTimeInput);
        onEndDateWasUpdated();
    }

    // Update the end date when the start date changes
    startDateInput
        .change(updateEndDateOnStartDateTimeChange)
        .change(storeAsPreviousValue);
    startTimeInput
        .change(updateEndDateOnStartDateTimeChange)
        .change(storeAsPreviousValue);
}

function storeAsPreviousValue() {
    $(this).data('previous', $(this).val());
}

function getPreviousValue(obj) {
    return obj.data('previous');
}

function mapDateTimeInputsToDate(dateInput, timeInput) {
    const date = dateInput.val();
    const time = timeInput.val();

    return mapDateTimeInputValuesToDate(date, time);
}

function mapDateTimeInputValuesToDate(date, time) {
    if ('' === date || '' === time) {
        return null;
    }

    const dateParts = date.split('.');

    return new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]} ${time}`);
}

function calculateDurationInMilliseconds(startDate, endDate, defaultDuration) {
    if (null === startDate || null === endDate) {
        return defaultDuration;
    }

    return endDate - startDate;
}

function setDateTimeInput(date, dateInput, timeInput) {
    dateInput.datepicker('update', date);
    timeInput.val(date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));
}
