import toastr from 'toastr';

export default class NotificationWindow {

    constructor() {
        toastr.options = {
            closeButton: true,
            debug: false,
            positionClass: 'toast-top-right',
            onclick: null,
            showDuration: '1000',
            hideDuration: '1000',
            timeOut: '5000',
            extendedTimeOut: '1000',
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut'
        };
    }

    showMessage(message, type = NotificationWindow.INFO) {
        switch (type) {
            case NotificationWindow.INFO:
                toastr.info(message);
                return;

            case NotificationWindow.SUCCESS:
                toastr.success(message);
                return;

            case NotificationWindow.WARNING:
                toastr.warning(message);
                return;

            case NotificationWindow.ERROR:
                toastr.error(message);
                return;
        }

        throw 'Unsupported notification type: ' + type;
    }

    showMessages(messages, type = NotificationWindow.INFO) {
        for (let i = 0; i < messages.length; ++i) {
            this.showMessage(messages[i], type);
        }
    }

}

NotificationWindow.INFO = 'info';
NotificationWindow.SUCCESS = 'success';
NotificationWindow.WARNING = 'warning';
NotificationWindow.ERROR = 'error';
