function sendHeight() {
    const mainContent = getPageContainerNode();

    if (!mainContent) {
        return;
    }

    const height = mainContent.scrollHeight;

    window.top.postMessage(
        {
            type: 'resizeIframe',
            height: height,
        },
        ADMIN_ORIGIN,
    );
}

sendHeight();

document.addEventListener('DOMContentLoaded', () => {
    sendHeight();

    const observedElement = getPageContainerNode();

    if (!observedElement) {
        return;
    }

    new ResizeObserver(sendHeight).observe(observedElement);
});

function getPageContainerNode() {
    return document.querySelector('.page-container');
}
