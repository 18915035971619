import jQuery from 'jquery';
import 'pwstrength-bootstrap';

jQuery(document).ready(function () {
    jQuery('#fos_user_change_password_form_plainPassword_first, #user_plainPassword_first').pwstrength({
        common: {
            minChar: 8,
        },
        ui: {
            showPopover: true,
            popoverPlacement: 'right'
        }
    });
});
