import jQuery from 'jquery';

jQuery(document).ready(function () {
    let updateFieldState = function(checkbox, isEnabled) {
        let container = checkbox.parents('.field');
        if (isEnabled) {
            container.find('.field-enabled').val('1');
            container.find('.field-addon').removeAttr('disabled');
        } else {
            container.find('.field-enabled').val('0');
            container.find('.field-addon').attr('disabled', 'disabled');
        }
    };

    let updateToggleBlock = function(checkbox, parentClass) {
        let container = checkbox.parents(parentClass);
        if (checkbox.is(':checked')) {
            container.removeClass('state-disabled');
        } else {
            container.addClass('state-disabled');
        }
    };

    let toggleProposalBlocks = function() {
        jQuery('.toggle-tab').each(function() {
            updateToggleBlock(jQuery(this), '.tab');
        });

        jQuery('.toggle-group').each(function() {
            updateToggleBlock(jQuery(this), '.group');
        });

        jQuery('.toggle-field').each(function() {
            updateToggleBlock(jQuery(this), '.field');
        });

        jQuery('.toggle-block.state-disabled .toggle-field').each(function() {
            updateFieldState(jQuery(this), false);
        });

        jQuery('.tab:not(.state-disabled) .group:not(.state-disabled) .field:not(.state-disabled) .toggle-field').each(function() {
            updateFieldState(jQuery(this), true);
        });

        jQuery('.tab').each(function() {
            let tabSelector = '.' + jQuery(this).attr('id');
            if (jQuery(this).hasClass('state-disabled')) {
                jQuery(tabSelector).addClass('tab-inactive');
            } else {
                jQuery(tabSelector).removeClass('tab-inactive');
            }
        });
    };

    jQuery('input.toggle-box').on('click, change', function() {
        toggleProposalBlocks();
    });

    toggleProposalBlocks();
});
