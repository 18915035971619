import jQuery from 'jquery';

import 'jquery-ui/sortable';

jQuery(document).ready(function () {
    var updatePositions = function() {
        var tab = 0;

        jQuery('.sortable-tabs').children().each(function() {
            var tabContainer = jQuery(this);
            var groupsList = '';

            tabContainer.find('.tab-position').val(tab++);

            tabContainer.find('.sortable-groups').children().each(function() {
                var groupContainer = jQuery(this);
                var fieldsList = '';

                groupsList += '|' + groupContainer.find('.group-id').val();

                groupContainer.find('.sortable-fields').children().each(function() {
                    var fieldContainer = jQuery(this);

                    fieldsList += '|' + fieldContainer.find('.field-id').val();
                });

                groupContainer.find('.field-order').val(fieldsList);
            });

            tabContainer.find('.group-order').val(groupsList);
        });
    };

    var enableSorting = function() {
        updatePositions();

        jQuery('.drag-handle').removeClass('d-none');
        jQuery('.btn-action').hide();

        jQuery('#start-sorting').addClass('d-none');
        jQuery('#save-sorting').removeClass('d-none');
        jQuery('#cancel-sorting').removeClass('d-none');

        jQuery('.sortable-tabs').sortable({
            'handle': 'ul.nav-tabs > li',
            'start': function(event, ui) {
                jQuery('.sortable-groups').hide();
                ui.placeholder.height(ui.item.find('.ui-sortable-handle').height());
                jQuery(this).sortable('refreshPositions');
            },
            'update': function(event, ui) {
                jQuery('.sortable-groups').show();
                updatePositions();
            }
        });

        jQuery('.sortable-groups').sortable({
            'handle': 'h2',
            'connectWith': '.sortable-groups',
            'over': function(event, ui) {
                jQuery(ui.placeholder).parent().addClass('sortable-over');
            },
            'out': function(event, ui) {
                jQuery(ui.placeholder).parent().removeClass('sortable-over');
            },
            'start': function(event, ui) {
                jQuery('.sortable-fields').hide();
                ui.placeholder.height(ui.item.find('.ui-sortable-handle').height());
                jQuery(this).sortable('refreshPositions');
            },
            'update': function(event, ui) {
                jQuery('.sortable-fields').show();
                jQuery('.sortable-over').removeClass('sortable-over');
                updatePositions();
            }
        });

        jQuery('.sortable-fields').sortable({
            'handle': '.drag-handle',
            'connectWith': '.sortable-fields',
            'over': function(event, ui) {
                jQuery(ui.placeholder).parent().addClass('sortable-over');
            },
            'out': function(event, ui) {
                jQuery(ui.placeholder).parent().removeClass('sortable-over');
            },
            'update': function(event, ui) {
                jQuery('.sortable-over').removeClass('sortable-over');
                updatePositions();
            }
        });
    };

    var disableSorting = function() {
        jQuery('.drag-handle').addClass('d-none');
        jQuery('.sortable-tabs').sortable('destroy');
        jQuery('.sortable-groups').sortable('destroy');
        jQuery('.sortable-fields').sortable('destroy');

        jQuery('#start-sorting').removeClass('d-none');
        jQuery('#save-sorting').addClass('d-none');
        jQuery('#cancel-sorting').addClass('d-none');

        jQuery('.btn-action').show();
    };

    jQuery('#start-sorting').on('click', function() {
        enableSorting();
    });

    jQuery('#cancel-sorting').on('click', function() {
        disableSorting();
    });
});
