import jQuery from 'jquery';
import NotificationWindow from './NotificationWindow';
import FormUtil from './FormUtil';
import BackButtonDetection from './BackButtonDetection';
import { navigateTo } from './navigation-utils';

import 'bootstrap';

jQuery(document).ready(function () {
    jQuery('select.page-size').change(function () {
        const href = jQuery(this).find(':selected').data('url');

        navigateTo(href);
    });

    FormUtil.init(document);
    BackButtonDetection.init();

    // Tabs need to be initialized as soon as they are shown for the first time
    jQuery('div.tab-pane.active').data('initialized', true);

    jQuery('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var $targetPane = jQuery(jQuery(e.target).attr('href'));

        if (!$targetPane.data('initialized')) {
            FormUtil.initPreviouslyHidden($targetPane);

            $targetPane.data('initialized', true);
        }
    });

    if ('undefined' !== typeof FLASH_NOTIFICATIONS && !BackButtonDetection.isBackButtonClicked()) {
        let notificationWindow = new NotificationWindow(document);

        notificationWindow.showMessages(FLASH_NOTIFICATIONS.info, NotificationWindow.INFO);
        notificationWindow.showMessages(FLASH_NOTIFICATIONS.success, NotificationWindow.SUCCESS);
        notificationWindow.showMessages(FLASH_NOTIFICATIONS.warning, NotificationWindow.WARNING);
        notificationWindow.showMessages(FLASH_NOTIFICATIONS.error, NotificationWindow.ERROR);
    }

    /**
     * example href could look like:
     * <a href="https://www.google.com" class="btn btn-danger open-modal-delete" data-modal-body="test<br/>asfasdf" data-modal-title="Ziel löschen">Löschen</a>
     */
    // add listener for css class delete-modal-helper
    jQuery('.open-modal-delete').on('click', function(event) {
        event.preventDefault();

        const target = jQuery(this);
        const modal = jQuery('#helper-modal');

        let cancelText = target.attr('data-modal-cancel') || 'Nein, nicht löschen';
        let submitText = target.attr('data-modal-submit') || 'Ja, löschen';

        modal.html('<div class="modal-dialog modal-md" role="document">\n' +
            '        <div class="modal-content">\n' +
            '            <div class="modal-header modal-header-danger"><h4 class="modal-title">' + target.attr('data-modal-title') + '</h4></div>\n' +
            '            <div class="modal-body">\n' +
            '                <div>' + target.attr('data-modal-body') + '</div>\n' +
            '            </div>\n' +
            '            <div class="modal-footer">\n' +
            '                <button type="button" class="btn btn-default" data-dismiss="modal">' +
            '                    ' + cancelText +
            '                </button>\n' +
            '                <a href="' + target.attr('href') + '" target="_self" class="btn btn-danger">' +
            '                   ' + submitText +
            '                </a>\n' +
            '            </div>\n' +
            '        </div>\n' +
            '    </div>');

        modal.modal('show');

        modal.on('hidden.bs.modal', function () {
            // Clear modal
            modal.html('');
        });
    });
});
