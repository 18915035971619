import jQuery from 'jquery';
import { updateEndDateAutomaticallyOnStartDateChange } from './end-date-updater';

jQuery(document).ready(function () {
    const DEFAULT_DURATION_IN_HOURS = 2;
    const DEFAULT_DURATION_IN_MILLISECONDS = hoursToMilliseconds(DEFAULT_DURATION_IN_HOURS);

    // input definitions
    const startDateInput = jQuery('#create_activity_task_startDate, #modify_activity_task_startDate').datepicker();
    const startTimeInput = jQuery('#create_activity_task_startTime, #modify_activity_task_startTime');
    const endDateInput = jQuery('#create_activity_task_endDate, #modify_activity_task_endDate').datepicker();
    const endTimeInput = jQuery('#create_activity_task_endTime, #modify_activity_task_endTime');
    const moveAllCheckboxContainer = jQuery('#moveAllTasksCheckboxContainer');
    const moveAllCheckbox = jQuery('#modify_activity_task_moveAllTasks');
    const initialDate = jQuery('#modify_activity_task_originalStartDate').val();
    const initialTime = jQuery('#modify_activity_task_originalStartTime').val();

    updateMoveAllTasksCheckboxVisibility();

    if (moveAllCheckbox.length) {
        startDateInput
            .change(updateMoveAllTasksCheckboxVisibility);
        startTimeInput
            .change(updateMoveAllTasksCheckboxVisibility);
    }

    updateEndDateAutomaticallyOnStartDateChange(
        {
            startDateInput,
            startTimeInput,
            endDateInput,
            endTimeInput,
            defaultDuration: DEFAULT_DURATION_IN_MILLISECONDS,
        },
    );

    function updateMoveAllTasksCheckboxVisibility() {
        if (didDateTimeChange()) {
            moveAllCheckboxContainer.removeClass('d-none');
        } else {
            moveAllCheckboxContainer.addClass('d-none');
            if (moveAllCheckbox.is(':checked')) {
                moveAllCheckbox.click();
            }
        }
    }

    function didDateTimeChange() {
        return didDateChange() || didTimeChange();
    }

    function didDateChange() {
        return initialDate !== startDateInput.val();
    }

    function didTimeChange() {
        return initialTime !== startTimeInput.val();
    }
});

export function hoursToMilliseconds(hours) {
    return hours * 60 * 60 * 1000;
}

