export function navigateTo(href) {
    if (shouldDelegateNavigation(href)) {
        // Send the clicked URL to the outer app
        delegateNavigation(href);
    } else {
        // Navigate to the URL
        doNavigate(href);
    }
}

export function shouldDelegateNavigation(href) {
    return !!href
        && isInIframe()
        && isInternalUrl(href);
}

function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function isInternalUrl(url) {
    return !!url
        && (isCrmUrl(url) || isRelativeUrl(url));
}

function isCrmUrl(url) {
    return url.startsWith(CRM_BASE_URL);
}

function isRelativeUrl(url) {
    try {
        // Try to create a URL object with just the URL
        new URL(url);

        // If no error is thrown, it's an absolute URL
        return false;
    } catch (e) {
        return true;
    }
}

export function didOnlyFragmentChange(href) {
    if (href.startsWith('#')) {
        return true;
    }

    if (!isRelativeUrl(href)) {
        const currentUrl = new URL(window.location.href);
        const newUrl = new URL(href);

        return areUrlsEqualExceptFragment(currentUrl, newUrl);
    }

    return false;
}

function areUrlsEqualExceptFragment(url1, url2) {
    url1.hash = '';
    url2.hash = '';

    return url1.toString() === url2.toString();
}

export function delegateNavigation(url) {
    const absoluteUrl = isRelativeUrl(url)
        ? new URL(url, window.location.href)
        : new URL(url);

    doDelegateNavigation(absoluteUrl);
}

function doDelegateNavigation(url) {
    window.top.postMessage(
        {
            href: url.toString(),
        },
        ADMIN_ORIGIN,
    );
}

function doNavigate(href) {
    window.location.href = href;
}
