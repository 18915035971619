import jQuery from 'jquery';
import _ from 'underscore';
import Dropdown from './Dropdown';

export default class AutocompleteDropdown extends Dropdown
{
    static fetch($container, id) {
        return new Promise(
            function (resolve, reject) {
                jQuery.ajax({
                    url: `${$container.data(AutocompleteDropdown.URL_DATA)}?id=${id}`,
                    success: function (data) {
                        resolve(data);
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        reject(errorThrown);
                    }
                });
            }
        );
    }

    getSelect2Params() {
        return _.extend(super.getSelect2Params(), {
            ajax: {
                url: this.$container.data(AutocompleteDropdown.URL_DATA),
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term,
                        page: params.page
                    };
                },
                processResults: function (data) {
                    return {
                        results: data.items,
                        pagination: {
                            more: data.currentPage < data.lastPage
                        }
                    };
                },
                cache: true
            },
            minimumInputLength: 1
        });
    }
}

AutocompleteDropdown.URL_DATA = 'autocomplete-url';
