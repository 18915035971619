import jQuery from 'jquery';
import FilterBuilder from './FilterBuilder';
import { updateEndDateAutomaticallyOnStartDateChange } from './end-date-updater';

jQuery(document).ready(function () {
    const eventAddEndDate = jQuery('#eventAddEndDate');

    eventAddEndDate.on('click', function(event) {
        event.preventDefault();
        jQuery(this).addClass('d-none');
        jQuery('#eventEndDateContainer').removeClass('d-none');
    });

    jQuery('#eventAddEndTime').on('click', function(event) {
        event.preventDefault();
        jQuery(this).addClass('d-none');
        jQuery('#eventEndTimeContainer').removeClass('d-none');
    });

    const startDateInput = jQuery('#create_event_startDate, #modify_event_startDate').datepicker();
    const startTimeInput = jQuery('#create_event_startTime, #modify_event_startTime');
    const endDateInput = jQuery('#create_event_endDate, #modify_event_endDate').datepicker();
    const endTimeInput = jQuery('#create_event_endTime, #modify_event_endTime');

    // integrate filter builder for activities and events
    new FilterBuilder({
        toggleButton: document.getElementById('filterToggle'),
        container: document.getElementById('filterBuilder'),
        filterAddEndDateButton: document.getElementById('filterAddEndDate'),
        filterAddEndDateContainer: document.getElementById('filterAddEndDateContainer'),
    });

    updateEndDateAutomaticallyOnStartDateChange(
        {
            startDateInput,
            startTimeInput,
            endDateInput,
            endTimeInput,
            defaultDuration: null,
            getEndDateInputValue,
            onEndDateWasUpdated: updateEndDateInputVisibility,
        },
    );

    function getEndDateInputValue() {
        const dateInput = '' === endDateInput.val()
            ? startDateInput
            : endDateInput;

        return dateInput.val();
    }

    function updateEndDateInputVisibility() {
        const pickedEndDateDiffersFromStartDate = endDateInput.val() !== startDateInput.val();

        if (endDateInput.is(':hidden') && pickedEndDateDiffersFromStartDate) {
            showEndDateInput();
        }
    }

    function showEndDateInput() {
        eventAddEndDate.click();
    }
});
