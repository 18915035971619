import AutocompleteDropdown from './AutocompleteDropdown';
import _ from 'underscore';

/**
 * @param {HTMLElement} htmlElement
 *
 * @return {boolean}
 */
function shouldOverrideExistingValues(htmlElement) {
    return 'off' !== htmlElement.getAttribute('override-values');
}

function replaceFormValues($form, data) {
    $form.find('.contact-salutation')
        .val(data.salutation)
        .trigger('change');
    $form.find('.contact-first-name').val(data.firstName);
    $form.find('.contact-last-name').val(data.lastName);
    $form.find('.contact-email').val(data.email);
    $form.find('.contact-id').val(data.id);
}

export default class ContactAutocompleteDropdown extends AutocompleteDropdown
{
    constructor(props) {
        super(props);

        this.$container.on('select2:select', event => {
            if (shouldOverrideExistingValues(this.$container[0])) {
                replaceFormValues(
                    this.$container.parents('form'),
                    event.params.data,
                );
            }
        });
    }
}
