import jQuery from 'jquery';

function hideUploadWorkbookFieldContainers () {
    var contactHistory = 'contactHistoryEntries';
    var modifiedContact = 'modifiedContacts';
    var proposedContacts = 'proposedContacts';
    var donations = 'donations';
    var workbookTypeValue = $('#upload_workbook_type').val();

    var contactHistoryEntryEntityContainer = $('#upload_workbook_contactHistoryImportEntity').parent().parent();
    var entityContainer = $('#upload_workbook_entity').parent().parent();

    contactHistoryEntryEntityContainer.hide();
    entityContainer.hide();

    switch (workbookTypeValue) {
        case contactHistory:
            contactHistoryEntryEntityContainer.show();
            break;

        case proposedContacts:
        case donations:
            entityContainer.show();
            break;
    }
}

jQuery(document).ready(function () {
    hideUploadWorkbookFieldContainers();

    $('#upload_workbook_type').change(() => hideUploadWorkbookFieldContainers());
});
