import jQuery from 'jquery';
import CollectionRow from './CollectionRow';

export default class CollectionForm {

    constructor(props) {
        let that = this;

        this.$container = jQuery(props.container);
        this.prototype = this.$container.data('prototype');
        this.nextIndex = this.$container.find(':input').length;
        this.rowType = props.rowType || CollectionRow;

        let supportsModification = !Boolean(that.$container.data('disabled'));

        this.$container.children().each(function () {
            let rowType = that.rowType;
            new rowType({
                container: this,
                allowDelete: supportsModification
            });
        });

        if (supportsModification) {
            this.initAddButton();
        }

        // needed by merge.js
        this.$container.data('index', this.nextIndex);
    }

    initAddButton() {
        let that = this;

        let $addButton = jQuery('<a class="btn btn-primary" href="#"><i class="far fa-plus"></i> ' + translations.collection.add + '</a>');
        let $addButtonWrapper = jQuery('<div class="col-sm-5"></div>').append($addButton);

        $addButton.on('click', function (e) {
            e.preventDefault();
            that.addRow();
        });

        this.$addButtonRow = jQuery('<div class="form-group addLink"></div>').append($addButtonWrapper);
        this.$container.append(this.$addButtonRow);
    }

    addRow() {
        let rowType = this.rowType;
        let $row = jQuery(this.prototype.replace(/__name__/g, this.nextIndex++));

        this.$addButtonRow.before($row);

        // Initialize after addition to the DOM
        new rowType({
            container: $row[0],
            allowDelete: true
        });

        // needed by merge.js
        this.$container.data('index', this.nextIndex);
    }

}
