import {
    delegateNavigation,
    didOnlyFragmentChange,
    shouldDelegateNavigation
} from './navigation-utils';
import jQuery from 'jquery';

document.addEventListener('click', function (event) {
    const anchor = event.target.closest('a');

    if (
        !anchor
        || anchor.target === '_blank'
        || !shouldDelegateNavigation(anchor.href)
        || didOnlyFragmentChange(anchor.href)
    ) {
        return;
    }

    // Prevent default link behavior (i.e., don't navigate in the Symfony app)
    event.preventDefault();
    event.stopPropagation();

    delegateNavigation(anchor.href);
});

document.addEventListener('submit', function (event) {

    const form = event.target;
    const method = form.getAttribute('method').toUpperCase();
    const url = new URL(form.getAttribute('action') || '', window.location.href);

    if (
        method !== 'GET'
        || !shouldDelegateNavigation(url.toString())
    ) {
        return;
    }

    // Prevent default form submission
    event.preventDefault();
    event.stopPropagation();

    // Build the URL for a GET request by appending query parameters
    const formData = new FormData(form);

    url.search = '';

    formData.forEach((value, key) => {
        url.searchParams.set(key, value);
    });

    delegateNavigation(url.toString());
});
