import jQuery from 'jquery';

import 'bootstrap-datepicker';

export default class DatePicker {

    constructor(props) {
        jQuery(props.container).datepicker({
            'format': 'dd.mm.yyyy'
        });
    }

}
