import jQuery from 'jquery';
import QueryBuilder from './QueryBuilder';

jQuery(document).ready(function () {
    if ('undefined' !== typeof QUERY_BUILDER_SHORTCUTS) {
        new QueryBuilder({
            container: document.getElementById('query-builder'),
            toggleButton: document.getElementById('query-builder-toggle'),
            shortcuts: QUERY_BUILDER_SHORTCUTS,
            filters: QUERY_BUILDER_FILTERS,
            operators: QUERY_BUILDER_OPERATORS,
            operatorNames: QUERY_BUILDER_OPERATOR_NAMES,
            rootRule: QUERY_BUILDER_ROOT_RULE
        });
    }
});
