import jQuery from 'jquery';

jQuery(document).ready(function () {
    let $synchronizationEnabledField = jQuery('#field_form_hubSpotSynchronizationEnabled');
    let $internalNameField = jQuery('#field_form_hubSpotPropertyName');
    let $synchronizationDirectionField = jQuery('#field_form_hubSpotSynchronizationDirection');
    let $forceSynchronizationField = jQuery('#field_form_hubSpotForceSynchronization');
    let $managedByCrmField = jQuery('#field_form_hubSpotManagedByCrm');
    let $labelField = jQuery('#field_form_hubSpotPropertyLabel');
    let $groupNameField = jQuery('#field_form_hubSpotGroupName');
    let $visibleInFormField = jQuery('#field_form_hubSpotVisibleInForm');
    let $fieldTypeFormField = jQuery('#field_form_fieldType');
    let $optInFieldIdFormType = jQuery('#field_form_optInFieldId');
    let $entityIdsFormType = jQuery('#field_form_entityIds');

    let onUpdateSynchronizationEnabled = function () {
        let synchronizationEnabled = $synchronizationEnabledField.is(':checked');
        let managedByCrm = $managedByCrmField.is(':checked');

        $internalNameField.prop('disabled', !synchronizationEnabled);
        $synchronizationDirectionField.prop('disabled', !synchronizationEnabled);
        $forceSynchronizationField.prop('disabled', !synchronizationEnabled);
        $managedByCrmField.prop('disabled', !synchronizationEnabled);
        $labelField.prop('disabled', !synchronizationEnabled || !managedByCrm);
        $groupNameField.prop('disabled', !synchronizationEnabled || !managedByCrm);
        $visibleInFormField.prop('disabled', !synchronizationEnabled || !managedByCrm);
    };

    let onUpdateManagedByCrm = function () {
        let managedByCrm = $managedByCrmField.is(':checked');

        $labelField.prop('disabled', !managedByCrm);
        $groupNameField.prop('disabled', !managedByCrm);
        $visibleInFormField.prop('disabled', !managedByCrm);
    };

    let onUpdateFieldType = function() {
        let fieldType = $fieldTypeFormField.val();

        $optInFieldIdFormType.parents('.form-group').hide();
        $entityIdsFormType.parents('.form-group').hide();

        if ('newsletter-checkbox' === fieldType) {
            $optInFieldIdFormType.parents('.form-group').show();
        } else if ('opt-in-checkbox' === fieldType) {
            $entityIdsFormType.parents('.form-group').show();
        }
    };

    $synchronizationEnabledField.on('change', onUpdateSynchronizationEnabled);
    $managedByCrmField.on('change', onUpdateManagedByCrm);
    $fieldTypeFormField.on('change', onUpdateFieldType);

    onUpdateSynchronizationEnabled();
    onUpdateFieldType();
});
