import Handlebars from 'handlebars';
import Bloodhound from 'bloodhound-js';
import jQuery from 'jquery';

import 'corejs-typeahead';

const TYPE_AHEAD_SETTINGS = {
    minLength: 1,
    highlight: true,
};

const REMOTE_SETTINGS = {
    name: 'regions',
    display: 'text',
    templates: {
        suggestion: Handlebars.compile('<div>{{#if newType}}<div class="region-type-start">{{newType}}</div>{{/if}}{{postalCode}} {{name}}</div>'),
    }
};

export default class AddressForm {

    constructor(props) {
        let $container = jQuery(props.container);
        let that = this;

        this.$postalCodeField = $container.find('.address-postal-code:first');
        this.$cityField = $container.find('.address-city:first');
        this.$provinceField = $container.find('.address-province:first');
        this.$countryField = $container.find('.address-country:first');

        this.bloodhound = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: $container.attr('data-autocomplete'),
                prepare: function (query, settings) {
                    var params = {
                        q: query,
                    };

                    var postalCode = that.$postalCodeField.val().trim();

                    if (postalCode.length >= 4) {
                        params.postalCode = postalCode;
                    }

                    settings.url = settings.url + '?' + jQuery.param(params);

                    return settings;
                },
                transform: function (response) {
                    if (VALIDATED_COUNTRY_CODE != that.$countryField.val() && '' != that.$countryField.val()) {
                        return [];
                    }

                    return response.items;
                }
            }
        });

        this.$countryField.on('change', function () {
            that.handleCountrySelected();
        });

        this.handleCountrySelected();
    }

    handleCountrySelected() {
        if (VALIDATED_COUNTRY_CODE == this.$countryField.val() || '' == this.$countryField.val()) {
            this.enableAutocomplete();

            return;
        }

        this.disableAutocomplete();
    }

    enableAutocomplete() {
        let that = this;

        this.$postalCodeField
            .typeahead(TYPE_AHEAD_SETTINGS, jQuery.extend(REMOTE_SETTINGS, {
                source: this.bloodhound,
                display: 'postalCode',
            }))
            .on('typeahead:select', function (event, suggestion) {
                that.handleSuggestionSelected(suggestion);
            });

        this.$cityField
            .typeahead(TYPE_AHEAD_SETTINGS, jQuery.extend(REMOTE_SETTINGS, {
                source: this.bloodhound,
                display: 'name',
            }))
            .on('typeahead:select', function (event, suggestion) {
                that.handleSuggestionSelected(suggestion);
            });
    }

    disableAutocomplete() {
        if (this.$postalCodeField.data('ttTypeahead')) {
            this.disableTypeAheadField(this.$postalCodeField);
        }

        if (this.$cityField.data('ttTypeahead')) {
            this.disableTypeAheadField(this.$cityField);
        }
    }

    handleSuggestionSelected(suggestion) {
        this.$postalCodeField.typeahead('val', suggestion.postalCode);
        this.$cityField.typeahead('val', suggestion.name);
        this.$provinceField.val(suggestion.province);
        this.$countryField.val(VALIDATED_COUNTRY_CODE);

        // Needs to be triggered manually
        this.$countryField.trigger('change');
    }

    disableTypeAheadField(field) {
        field.typeahead({
            hint: false,
        }, {
            source: () => [],
        });
    }
}
