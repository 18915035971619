import jQuery from 'jquery';
import 'corejs-typeahead';

let toFixedNumber = function (str) {
    return parseFloat(str).toFixed(7);
};

jQuery(document).ready(function () {
    const $map = jQuery('#placeMap');

    const $name = jQuery('#create_place_name, #modify_place_name');
    const $street = jQuery('#create_place_address_street, #modify_place_address_street');
    const $street2 = jQuery('#create_place_address_street2, #modify_place_address_street2');
    const $city = jQuery('#create_place_address_city, #modify_place_address_city');
    const $province = jQuery('#create_place_address_province, #modify_place_address_province');
    const $postalCode = jQuery('#create_place_address_postalCode, #modify_place_address_postalCode');
    const $country = jQuery('#create_place_address_countryCode, #modify_place_address_countryCode');

    const $latitude = jQuery('#create_place_latitude, #modify_place_latitude');
    const $longitude = jQuery('#create_place_longitude, #modify_place_longitude');
    const $zoomLevel = jQuery('#create_place_zoomLevel, #modify_place_zoomLevel');

    const $refreshLocation = jQuery('#refreshLocation');

    let options = {
        center: {
            lat: DEFAULT_LATITUDE,
            lng: DEFAULT_LONGITUDE,
        },
        zoom: DEFAULT_ZOOM_LEVEL,
    };

    let center = null;
    if ($latitude.val() !== '' && $longitude.val() !== '') {
        center = {
            lat: parseFloat($latitude.val()),
            lng: parseFloat($longitude.val())
        };
        options.center = center;
    }
    if ($zoomLevel.val() !== '') {
        options.zoom = parseInt($zoomLevel.val());
    }

    let map = new google.maps.Map($map[0], options);
    let autocompleteName = new google.maps.places.Autocomplete($name[0]);
    let autocompleteStreet = new google.maps.places.Autocomplete($street[0]);

    const onAutocompleteKeyDown = function(event) {
        if (event.keyCode === 13 && $('.pac-container:visible').length) {
            event.preventDefault();
        }
    };

    $name.on('keydown', onAutocompleteKeyDown);
    $street.on('keydown', onAutocompleteKeyDown);

    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    autocompleteName.bindTo('bounds', map);
    autocompleteStreet.bindTo('bounds', map);

    // Set the data fields to return when the user selects a place.
    autocompleteName.setFields(['address_components', 'geometry', 'icon', 'name']);
    autocompleteName.setTypes([]); //'address'

    let marker = new google.maps.Marker({
        map: map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: true,
    });

    if ($latitude.val() !== '' && $longitude.val() !== '') {
        marker.setPosition(center);
    }

    google.maps.event.addListener(marker, 'dragend', function () {
        let position = marker.getPosition();
        $latitude.val(toFixedNumber(position.lat()));
        $longitude.val(toFixedNumber(position.lng()));
    });

    const onPlaceChanged = function (autocomplete) {
        let place = autocomplete.getPlace();

        if (!place || !place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            return;
        }

        let length = place.address_components.length;
        let street = '';
        let streetNumber = '';
        let city = '';
        let postalCode = '';
        let province = '';
        let country = '';

        for (let index = 0; index < length; index++) {
            let addressComponents = place.address_components[index];

            if (addressComponents.types.indexOf('street_number') > -1) {
                streetNumber = addressComponents['long_name'];
            } else if (addressComponents.types.indexOf('route') > -1) {
                street = addressComponents['long_name'];
            } else if (addressComponents.types.indexOf('locality') > -1) {
                city = addressComponents['long_name'];
            } else if (addressComponents.types.indexOf('postal_code') > -1) {
                postalCode = addressComponents['long_name'];
            } else if (addressComponents.types.indexOf('administrative_area_level_1') > -1) {
                province = addressComponents['long_name'];
            } else if (addressComponents.types.indexOf('country') > -1) {
                country = addressComponents['short_name'];
            }
        }

        if (country === VALIDATED_COUNTRY_CODE) {
            jQuery.ajax({
                url: $map.data('cleanDestinationNameUrl'),
                method: 'POST',
                dataType: 'json',
                data: {
                    'postalCode': postalCode,
                    'city': city,
                    'countryCode': country,
                },
                success: function (data) {
                    if (data.status) {
                        $city.typeahead('val', data.city);
                        $province.val(data.province);
                    } else {
                        insertDataFromGoogle(city, province);
                    }
                },
                error: function (xhr, textStatus, errorThrown) {
                }
            });
        } else {
            insertDataFromGoogle(city, province);
        }

        if (autocomplete === autocompleteName) {
            $name.val(place.name);
        }

        $street.val(street + ' ' + streetNumber);
        $postalCode.typeahead('val', postalCode);
        $country.val(country).trigger('change');

        // set latitude,longitude, zoomLevel, marker position and map center
        // only if position is empty
        if ('' === $latitude.val() && '' === $longitude.val() && '' === $zoomLevel.val()) {
            setPlaceLocation(place);
        }
    };

    const setPlaceLocation = function(place) {
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);  // Why 17? Because it looks good.
        }

        marker.setPosition(place.geometry.location);

        $latitude.val(toFixedNumber(place.geometry.location.lat()));
        $longitude.val(toFixedNumber(place.geometry.location.lng()));
        $zoomLevel.val(map.getZoom());
    };

    autocompleteName.addListener('place_changed', function() {
        onPlaceChanged(autocompleteName);
    });
    autocompleteStreet.addListener('place_changed', function() {
        onPlaceChanged(autocompleteStreet);
    });

    map.addListener('zoom_changed', function () {
        $zoomLevel.val(map.getZoom());
    });

    const onPositionChange = function () {
        const latitudeString = $latitude.val().replace(',', '.');
        const longitudeString = $longitude.val().replace(',', '.');

        $latitude.val(latitudeString);
        $longitude.val(longitudeString);

        let position = {
            lat: parseFloat(latitudeString),
            lng: parseFloat(longitudeString)
        };

        if (!position.lat || !position.lng) {
            return;
        }

        map.setCenter(position);
        marker.setPosition(position);
    };

    $latitude.bind('blur', onPositionChange);
    $longitude.bind('blur', onPositionChange);

    $zoomLevel.bind('blur', function () {
        let zoomLevel = parseInt($zoomLevel.val());

        if (!zoomLevel) {
            return;
        }

        map.setZoom(zoomLevel);
    });

    let geocoder = new google.maps.Geocoder();
    $refreshLocation.on('click', function(event){
        event.preventDefault();

        let address = [];
        address.push($street.val());
        address.push($street2.val());
        address.push($postalCode.val());
        address.push($city.val());
        address.push($province.val());
        address.push($country.find('option:selected').text());

        geocoder.geocode(
            {'address': address.join(' ')},
            function(results, status) {
                if (status !== 'OK') {
                    return;
                }

                setPlaceLocation(results[0]);
            }
        );
    });

    const insertDataFromGoogle = function(city, province) {
        $city.typeahead('val', city);
        $province.val(province);
    };
});
